.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--primary-color);
  width: 14vw;
  margin-top: 3vh;
  margin-left: 1vw;
  margin-bottom: 3vh;
  height: 94vh;
  border-radius: 15px;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.5);

  p{
    color:#a0d0ff;
  }
}


body.dark-mode{
  .side-menu{
    background: none;
    background-color: var(--primary-color);

  }

  .icon-text{
    color: var(--text-color-dark);
  }

  .icon-text.selected{
    color: var(--primary-color);
  }
  .menu-item:hover{
    background-color: #333;
  }
}

.header-image{
  margin-top: 20px;
  width: 100%;
  height: 6.5vh;
  background: var(--gradient-light-menu);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.nav-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 80%;
}

.user-info{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;
  padding-bottom: 0;
  background-color: var(--primary-color);
  color: var(--text-color-light);
}
.company{
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-dark);
}
.menu-item {
  display: flex;
  align-items: center;
  width: 90%;
  height: 6vh;
  padding: 5px;
  margin-top: 0px;
  margin-bottom: 15px;
  border-radius: 15px;
  transition: all 0.2s;
  text-decoration: none;
}

.menu-item.selected{
  background-color: #e9ecef;
  color: var(--primary-color);

}

.icon-text.selected{
  color: var(--primary-color);
  font-weight: 600;
}

.icon.selected{
  color: var(--primary-color);
  font-weight: 600;
}


.info-box{
  background-color: rgb(60, 148, 255,0.8);
  width: 85%;
  height: 50%;
  border-radius: 12.5px;
  margin-bottom: 7.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  color: var(--text-color-dark);
}

.text-box{
  padding-left: 25px;
  padding-right: 25px;
}

.contact-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:var(--accent-color);
  color: var(--text-color-dark);
  width: 85%;
  height: 5vh;
  border-radius: 10px;
  opacity: 0.8;
  font-weight: bold;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #e9ecef;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.2s;

  .icon-text{
    color: var(--primary-color);
    font-weight: 600;
  }

  .icon{
    color: var(--primary-color);
    font-weight: 600;
  }

}

.icon-text {
  color: var(--text-color-dark);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
}


.icon {
  font-size: 30px;
  color: var(--text-color-dark);
  margin-right: 20px;
  margin-left: 40px;
}

.menu-container{
  width: 100%;
  text-align: left;
  margin-left: 10vh;
  margin-bottom: 0px;
  cursor: default;
  font-size: 14px;
}