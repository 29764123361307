.folder-page {
  width: 88vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.folder-view {
  padding: 20px;
  margin-left: 1vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  font-size: 3rem;
  margin-left: 70px;
}

body.dark-mode{

  .file-window{
    background-color: var(--background-color-dark) ;
    color: white;
  }
  .path-display{
    background-color: var(--text-color-light) ;
    color: white;
  }
  .folder{
    color: white;
  }
  .file-name{
    color: white;
  }
  .home{
    background-color: var(--background-color-dark) ;
    color: white;
  }

  .home:hover{
    background-color: #050505 ;
  }
  .back-button:hover{
    background-color: #050505 ;
  }
}

.file-window {
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--background-color-light) ;
  padding: 10px;
  width: 95%;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}

.file-window::-webkit-scrollbar {
  width: 12px;
}

.file-window::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.file-window::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.file-window::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body.dark-mode .file-window::-webkit-scrollbar-track {
  background: #2c2c2c;
}

body.dark-mode .file-window::-webkit-scrollbar-thumb {
  background-color: #666;
  border: 3px solid #2c2c2c;
}

body.dark-mode .file-window::-webkit-scrollbar-thumb:hover {
  background: #444;
}
.path-display {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #e0e0e0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: rgb(24, 24, 24);
  font-weight: 600;
  margin-left: 2.5%;
  margin-right: 7.5%;
}

.folder {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(31, 31, 31);
}

.home{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left:10px ;
  color:rgb(11, 11, 11);
  text-decoration: none;
  background-color: var(--background-color-light);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  width: fit-content;
  border-radius: 10px; 
  transition: all 0.2s;
}

.home:hover{
  background-color: #aaa;
  transition: all 0.2s;
}

.home-icon{
  font-size: 40px;
  transition: all 0.2s;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.folder span {
  margin-right: 10px;
}



.buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.back-icon {
  font-size: 40px;
  transition: all 0.2s;
  padding-top: 5px;
  padding-bottom: 5px;
}

.file {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  transition: all 0.2s;
}

.open-button{
  border-radius: 15px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  padding: 3px 10px;
  margin-right: 10px;
}

.indexed-status.not_indexed{
  color: white;
  background-color: red;
  border-radius: 15px;
  padding: 3px 10px 3px 10px;
}

.right-side-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 1vw;
  font-size: 1rem;
}

.file:hover {
  background-color: rgb(206, 206, 206, 0.75);
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.2s;
}

.file-icon {
  font-size: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 12px;
}

.file-name {
  margin-left: 10px;
  cursor: pointer;
  color: black;
}
