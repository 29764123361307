/* Chat.css */
.chat-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  width: 106vw;
  padding-left: 1vw;
  overflow: visible;
  transition: background-color 0.3s ease;
  animation: slideIn 0.5s ease forwards; /* Apply the animation */
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.chatContainer .loading-spinner-container {
  position: absolute;
}

.chatAndFilters{
  height: 94vh;
}


.chatContainer {
  width: 83vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: var(--background-color-light);
  transition: width 0.3s ease-in-out;
  position: relative;
}

.chatContainer.reduced {
  width: 63vw; /* Adjust the width when history panel is open */
}

.source-button{
  border: none;
  background-color: transparent;
  color: white;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
}


.toggle-history{
  position: absolute;
  right: 20px;
  top: 20px;
  color: var(--text-color-light);
  font-size: 30px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.toggle-history.reduced{
  transform: rotate(180deg);
}

.toggle-history:hover{
  background-color: #bebebe;
  transition: background-color 0.2s ease;
}

.description-text{
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.watermark-container{
  width: 30vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-watermark{
  width: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 0.8;
}

@media (max-width: 768px){
  .main-watermark{
    width: 300px;
    height: fit-content;
    object-fit: cover;
    object-position: center center;
  }
  .watermark{
    padding-top: none;
  }
}

@media (max-width: 767px){
  .watermark p{
    max-width: 300px;
    color: var(--text-color-light) ;
  }
}

.watermark{
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 9vh;
}

body.dark-mode .watermark p{
  color: #E2E8F0; /* Light text color */
}

.watermark p{
  font-size: 1.25rem;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 300px;
  color: var(--text-color-light);
}

.watermark h2{
  color: #333333;
}

.chatHistory {
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  max-height: calc(100vh - 120px - 20px);
  width: 100%;
  margin: 0 auto; /* Center horizontally */
  flex-grow: 1; /* Allow to grow */
  background: transparent;
}

@media (min-width: 768px) {
  .chatHistory {
    width: 60%; /* Set width to 60% */
  }
}

@media (max-width: 768px) {
  .chatHistory {
    width: 100%; /* Set width to 60% */
    max-height: 90vh;
    padding-top: 80px;
  }
}

.message-me, .message-bot {
  border-radius: 15px;
  padding: 10px 20px;
  margin: 4px 0;
  max-width: 90%;
  word-wrap: break-word;
  white-space: normal;
}

.message-bot {
  align-self: flex-start;
  background-color: #007bff ; /* Deep blue */
  color: #ffffff;
}

.message-me {
  align-self: flex-end;
  background-color: #dbdbdb;
  color: #000000;
}

.messageForm {
  padding: 1rem;
  display: flex;
  align-items: center; /* Center align the form elements */
  box-sizing: border-box;
  width: 100%; /* Ensure form takes full width */
  justify-content: center; /* Center form elements */
}

.messageInput {
  flex-grow: 1;
  border-radius: 30px;
  padding-left: 15px;
  padding-top: 17.5px;
  padding-right: 13px;
  resize: none;
  overflow-y: hidden;
  line-height: 1;
  max-height: 120px;
  max-width: 70%; /* Limit width */
  box-sizing: border-box;
  background-color: #F5F5F5;
  align-items: center; /* Center the text vertically */
}

@media (max-width: 767px){
  
  .messageInput {
    max-width: 100%; /* Set width to 100% */
    padding-left: 10px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    margin-right: 20px;
  }
}

.messageInput::placeholder {
  color: #333333 ;
}

@media (min-width: 768px) {
  .messageInput {
    margin-left: 0; /* Ensure no extra margin */
  }
}

textarea.messageInput {
  min-height: 40px; /* Ensure it doesn't collapse completely */
  padding-left: 25px;
}

.buttonContainer {
  display: flex;
  margin: 10px;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 767px){
  .buttonContainer {
    margin: 5px; /* Set width to 100% */
  }
}

.send, .newChat {
  width: 70px;
  font-size: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center align the icons */
  color: var(--primary-color) ; /* Deep blue for icons */
}

@media (max-width: 767px){  
  .send{
    width: 40px;
    font-size: 40px;
    cursor: pointer;
    display: flex;
    position: fixed;
    right: 12px;
    bottom: 20px;
    color: #4A90E2 ; /* Deep blue for icons */
  }


}

@media (max-width: 767px){  
  .newChat{
    display: none;
  }
}


body.dark-mode .send .newChat {
  color: var(--primary-color) ; /* Light text color */
  transition: color 0.3s ease;
}


.message-bot pre, .message-me pre {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: calc(90vw - 20px);
  padding: 10px;
  margin: 0;
}

.message {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 1rem;
  max-width: 90vw;
}

html, body {
  max-height: 100vh;
  overflow: hidden;
}

.chatHistory::-webkit-scrollbar {
  width: 10px;
  opacity: 0;
}

.chatHistory::-webkit-scrollbar-track {
  background: transparent;
}

.chatHistory::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 10px;
}

.chatHistory::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

.chatHistory:hover::-webkit-scrollbar, .chatHistory:active::-webkit-scrollbar {
  display: block;
  transition: opacity 0.3s ease;
}

body.dark-mode .page{
  background-color: var(--gradient-dark); /* Dark background */
  color: #f5f5f5; /* Light text color */
  transition: background-color 0.3s ease;
}
body.dark-mode .messageInput{
  background-color: #2D3748 ; /* Dark background */
  color: #E2E8F0; /* Light text color */
  border: var(--background-color-dark);
  transition: background-color 0.3s ease;
}

body.dark-mode .messageInput::placeholder{
  color: #E2E8F0; /* Light text color */
  transition: background-color 0.3s ease;
}
body.dark-mode .chat-history-panel{
  color: #E2E8F0 ; /* Light text color */
  background-color: #2D3748;
}
body.dark-mode li:hover{
  color: #ffffff; /* Light text color */
  transition: background-color 0.3s ease;
  background-color: #262e3d;
}

body.dark-mode h3{
  color: #E2E8F0; /* Light text color */
}
body.dark-mode h4{
  color: #E2E8F0; /* Light text color */
}

.message pre {
  white-space: pre-wrap; /* Ensures that preformatted text wraps */
  word-wrap: break-word; /* Prevents long words from breaking the layout */
  margin: 0; /* Resets margin for preformatted text */
}

.message code {
  white-space: pre-wrap; /* Ensures that code blocks wrap */
  word-wrap: break-word; /* Prevents long words from breaking the layout */
}

.message ul,
.message ol {
  padding-left: 20px; /* Adjust padding to handle list indentation */
}

.message blockquote {
  padding-left: 10px; /* Adjust padding to handle blockquote indentation */
  border-left: 2px solid #ddd; /* Add a left border for blockquotes */
  margin: 0; /* Resets margin for blockquotes */
}

.message p {
  margin: 0; /* Resets margin for paragraphs */
}

.message {
  margin-bottom: 10px; /* Adds some space between messages */
}

body.dark-mode {
  .chatContainer {
    background-color: var(--background-color-dark) ; /* Dark background */
    color: var(--text-color-dark); /* Light text color */
    transition: all 0.3s ease;
  }
}
