/* semanticSearch.css */


.SemanticSearch {
    width: 80vw;
    display: flex;
    height: 93.5vh;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s ease;
    margin-top: 3.5vh;
    margin-left: 2.5vw;
    animation: slideIn 0.5s ease; /* Apply the animation */
}

@media (max-width: 768px) {
    .SemanticSearch {
        height: 90vh;
        margin-top:20%;
    }
}

/* Container for the search bar to center it */
.search-bar-container {
    width: 100%; /* Changed from 80% to 100% */
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 3vh;

}


/* Styling the form that contains the search bar */
.semantic-search-bar {
    width: 80%; /* Ensure the search bar takes 80% of the screen width */
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
}

body.dark-mode .semantic-search-bar {
    background: var(--background-color-dark);
    color: white;
    transition: background-color 0.3s ease;
}
body.dark-mode .semantic-search-bar input {
    background: var(--background-color-dark);
    color: white;
    transition: background-color 0.3s ease;
}
body.dark-mode .semantic-search-bar input {
    background: var(--background-color-dark);
    color: white;
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {

    .semantic-search-bar {
        width: 100%; /* Ensure the search bar takes 95% of the screen width */
        margin: none;
        padding: none;
        transform: none;
    }

    .semantic-search-bar input {
        width: 80%; /* Adjust width to take 80% of the container */
        border-radius: 10px 0 0 10px;
        overflow-x: hidden;
        margin: none;
        padding: none;
        transform: none;

    }

    .semantic-search-bar button {
        max-width: 80px; /* Adjust width to take 20% of the container */
        margin: none;
        padding: none;
        transform: none;
        border-radius: 10px 0 0 10px;
    }

    .search-bar-container {
        width: 90%; /* Changed from 80% to 100% */
        display: flex;
        justify-content: center;
        margin: none;
        padding: none;
        transform: none;

    }

    .search-results{
        min-width: 95%;
    }

}



/* Style the input field */
.semantic-search-bar input {
    flex-grow: 1; /* Allows the input to take remaining space */
    padding: 10px;
    border: none;
    border-radius: 10px 0 0 10px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    background-color: var(--background-color-light);
}

/* Style the search button */
.search-button {
    padding: 10px 20px;
    border: none;
    display: flex;
    border-radius: 0 10px 10px 0;
    background-color: var(--primary-color);
    color: white;
    font-weight: 500;
    font-size: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}
/* Add a focus effect to the input */
.semantic-search-bar input:focus {
    outline: none;
}

/* Adjustments for the search results */
.search-results {
    width: 100%;
    margin: 0 10px 0 10px;   
    height: 86vh;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 100px; /* Added padding at the bottom */
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.5);
    background: var(--background-color-light);
    border-radius: 10px;
    transition: all 0.3s ease;
}

.search-results::after {
    content: "";
    position: fixed;
    bottom: 3vh;
    left: 18vw;
    width: 78%;
    right: 10px; /* Adjusted to account for scrollbar */
    height: 100px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, white 100%);
    pointer-events: none;
}


.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

body.dark-mode .search-results {
    background: var(--background-color-dark);
    color: white;
    transition: all 0.3s ease;
}

body.dark-mode .search-button {
    background: #005aba;
    transition: all 0.3s ease;
}

.result-item {
    background: #fff;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    transition: all 0.3s ease;
}

body.dark-mode .result-item {
    background: #1a1a1a;
    color: white;
}

.highlight {
    background-color: yellow;
    font-weight: bold;
  }

body.dark-mode .search-results::after {

    display: none;
}

/* If you want to emphasize certain parts of the card, you could add additional styles */
.result-item pre {
    font-size: 1.1rem;
    font-weight: 400;
}

/* Add a title to each card for better hierarchy */
.result-item-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0;
}

.result-item:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

/* Add transitions for interactive elements */
.search-bar button,
.search-bar input:focus {
    transition: all 0.3s ease;
}

/* Hover effect for the button */
.semantic-search-bar button:hover {
    background-color: #0064ce;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

/* Add a loading spinner for better user experience */
