.file-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40px; /* Adjust this value between 50-100px as needed */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: height 0.3s ease;
    background-color: var(--primary-color);
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.file-card.expanded {
    height: 350px; /* Adjust this value between 300-400px as needed */
}

.file-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    height: 40px; /* Same as the non-expanded card height */
    color: white;
}

.file-card-name {
    font-size: 14px;
    color: white;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-card-actions {
    display: flex;
    align-items: center;
}

.remove-file,
.details-toggle {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.details-toggle svg {
    margin-right: 5px;
}

.file-card-content {
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 15px;
    height: calc(90% - 40px); /* Full height minus header height */
    overflow-y: scroll;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(168, 210, 255, 0.5) transparent;
}

.file-card-content::-webkit-scrollbar {
    width: 8px;
}

.file-card-content::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.file-card-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 123, 255, 0.5);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.file-card-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 123, 255, 0.7);
}

.custom-field {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.custom-field input {
    flex: 1;
}

.remove-custom-field {
    background: none;
    border: none;
    color: #ff4d4d;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    display: flex;
    align-items: center;
}

body.dark-mode .file-card-content::-webkit-scrollbar-thumb {
    background-color: rgba(200, 200, 200, 0.7);
}

body.dark-mode .remove-custom-field {
    color: #ff6b6b;
}

.input-fields {
    display: flex;
    flex-direction: column;
}

.input-fields input,
.input-fields select {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-top: 10px;
}

.custom-field {
    display: flex;
    gap: 10px;
}

.custom-field input {
    flex: 1;
}

.add-custom-field {
    align-self: flex-start;
    padding: 8px 12px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.add-custom-field:hover {
    background-color: var(--primary-color-dark, #0056b3);
}

body.dark-mode .file-card {
    background-color: var(--background-color-dark, #333);
}

body.dark-mode .file-card-header {
    background-color: var(--primary-color-dark, #0056b3);
}

body.dark-mode .input-fields input,
body.dark-mode .input-fields select {
    background-color: var(--background-color-dark, #333);
    color: var(--text-color-light, #fff);
    border-color: var(--border-color-dark, #555);
}