.settings-main-area{
    padding-left: 20px;
    padding-top: 10px;
    background-color: white;
    height: 94vh;
    width: 80vw;
    margin-left: 1vw;
    margin-top: 3vh;
    border-radius: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    font-family: 'Arial';
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-header{
    font-size: 24px;
    font-weight: 600;
    color: #333;
    padding: 10px 30px;
}

.email-settings{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.email-settings-header{
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

.email-providers-list{
    display: flex;
    flex-direction: column;
}

.email-provider-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.mail-icon{
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.settings-button{
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    width: 15vw;
    box-shadow: none;
    transition: all 0.3s ease;
}

.settings-button:hover{
    background-color: #005aba;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
}