.email-main-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 82vw;
    background-color: #f5f5f5;
    padding: 20px;
    margin-left: 1vw;
    margin-top: 3vh;
    height: 94vh;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    font-family: 'Arial';
}


.emails-preview-list {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 70%;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 20px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
    background: #ffffff;
}

.emails-preview-list::-webkit-scrollbar {
    width: 10px;

}


.email-page-header{
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.emails-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
}

.email-item{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px;
    border-bottom: 1px solid #e0e0e0;
    transition: all 0.3s ease;
    overflow: visible;
    border-radius: 10px;
}

.email-item:hover{
    background-color: #f0f0f0;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: visible;
}

.email-subject{
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0;
    margin-left: 10px;
}

.email-from{
    font-size: 14px;
    color: #333;
    margin: 0;
    margin-left: 10px;
}
.email-to{
    font-size: 14px;
    color: #333;
    margin: 0;
    margin-left: 10px;
}