.admin-page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.main-panel{
    background-color: var(--primary-color);
    width: 83vw;
    height: 93.5vh;
    margin-left: 1vw;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.admin-top-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
    padding: 15px;
    font-size: 24px;
    color: white;
    border-radius: 15px 15px 0 0;
}

.admin-content{
    background-color: white;
    height: calc(93.5vh - 7vh);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-org-name{
    font-size: 28px;
    color: var(--primary-color);
}

.admin-metric-cards{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.admin-metric-card{
    background-color: var(--primary-color);
    color: white;
    width: 20%;
    height: 10vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.admin-metric-card-value{
    font-size: 40px;
}

.admin-user-list{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.admin-user-list-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: white;
}

.admin-user-list-header-item{
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.admin-user-list-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid rgb(190, 190, 190);
}

.admin-user-list-entry-item{
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}


.admin-user-list-body{
    display: flex;
    flex-direction: column;
    padding: 10px 2px 2px 10px;
    height: 40vh;
    overflow-y: scroll;
    background-color: white;
    border-radius: 15px;
    position: relative; /* Add this */
}



/* Scrollbar styles */
.admin-user-list-body::-webkit-scrollbar {
    width: 8px; /* Slightly narrower scrollbar */
    z-index: 1;
}

.admin-user-list-body::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
}

.admin-user-list-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: 2px solid white; /* Match the background color */
    background-clip: padding-box;
}

.admin-user-list-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.admin-user-list-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
    color: white;
    width: 100%;
}

.admin-add-user-button{
    margin-top: 10px;
    background-color: var(--primary-color);
    border: none;
    padding: 3px 15px;
    border-radius: 15px;
    color: white;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s;
}
.admin-add-user-icon {
    font-size: 20px;
    margin-right: 10px;
}

.admin-add-user-button:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
    transition: all 0.3s;
}

.admin-manage-user-groups-button{
    margin-top: 10px;
    background-color: var(--primary-color);
    border: none;
    padding: 3px 15px;
    border-radius: 15px;
    color: white;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s;
}

.admin-manage-user-groups-button:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
    transition: all 0.3s;
}