.cloud-file-picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cloud-file-picker-content {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    width: 65vw;
    height: 85vh;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ff3b30;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #ff1a0d;
}

.big-x {
    font-size: 2rem;
    color: #ff3b30;
}

.add-selected-files-button {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
    font-weight: 600;
    font-size: 1rem;
}

.add-selected-files-button:hover {
    background-color: var(--primary-color-dark, #0056b3);
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.cloud-service-container {
    padding: 16px;
    border-bottom: 1px solid var(--background-color-light);
    height: calc(100% - 80px);
    overflow-y: auto;

    width: 100%;
}

.cloud-file-select-card {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid var(--background-color-light);
    margin-bottom: 12px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
}

.cloud-file-select-card:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cloud-file-select-card input {
    margin-right: 16px;
    cursor: pointer;
    scale: 1.2;
}

.cloud-file-select-card label {
    flex-grow: 1;
    cursor: pointer;
    font-size: 0.95rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Custom scrollbar for better aesthetics */
.cloud-service-container::-webkit-scrollbar {
    width: 8px;
}

.cloud-service-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.cloud-service-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.cloud-service-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .cloud-file-picker-content {
        width: 90vw;
        height: 90vh;
    }
}