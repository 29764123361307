.chat-history-panel {
  width: 19vw;
  height: 94%;
  background-color: var(--background-color-light);
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  border-radius: 15px;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


h3 {
  color: #333333;
  font-weight: 500;
}

@media (max-width: 767px) {
  .chat-history-panel {
    position: fixed;
    top: 10%;
    width: 80%;
    height: 80%;
    right: -1%;
    transform: translateX(100%);
  }
}

.chat-history-panel.open {
  transform: translateX(0); /* Slide in the panel */
}

.history-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow-y: auto;
  text-align: center;
  width: 90%;
}


@media (max-width: 767px) {
  .hamburger-icon {
    margin: 10px;
  }
}

.chat-history-panel .loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}


.history-content h4 {
  text-align: left;
  color: #333333 ;
  border-bottom: 1px solid #e0e0e0;
  /* Add a line between date group headers */
  padding-bottom: 5px;
}

.history-content h3 {
  margin-top: 0;
  text-align: center;
  color: 333333 ;
  /* Center the title */
}

.history-content h4 {
  padding-left: 10px;

  text-align: left;
  color: #333333 ;
  font-size: 12px;
  font-weight: 400;
}

.history-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.history-content li {
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s, transform 0.3s ease-out, opacity 0.3s ease-out;
  text-wrap: nowrap;
  overflow-x: hidden;
  color: #333333;
  transform: translateY(20px);
  opacity: 0;
}

.chat-history-panel.open .history-content li {
  transform: translateY(0);
  opacity: 1;
}

.session-content {
  position: relative;
}

@media (max-width: 767px) {
  .chat-history-panel {
    position: fixed;
    top: 10%;
    width: 80%;
    height: 80%;
    right: -1%;
    transform: translateX(100%);
  }
  
  .chat-history-panel.open {
    transform: translateX(0);
  }
}


.rename-input {
  width: 80%;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  margin-bottom: 5px;
  margin-right: 5px;
}

.submit-change-name{
  background-color: var(--primary-color);
  color: white;
  padding: 5px 10px ;
  border: none;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-change-name:hover{
  background-color: #164c85;
  transition: background-color 0.3s ease;
}

.user-info {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.user-icon {
  font-size: 24px;
  margin-right: 10px;
  color: var(--background-color-light);
}

.user-name {
  font-size: 16px;
  font-weight: bold;
  color: var(--background-color-light);
}

.rename-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  background-color: var(--background-color-light);
  border-radius: 5px;
  transition: all 0.3s;
}

.session-content:hover .rename-icon {
  opacity: 1;
  font-size: 18px;
}

.history-content li:hover {
  background-color: #e0e0e0;
  /* Background color change on hover */
  transition: all 0.3s;
}

.history-content .session-id {
  font-weight: bold;
}

.history-content .timestamp {
  font-size: 0.85em;
  color: #888;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.history-content::-webkit-scrollbar {
  display: none;
}

body.dark-mode .chat-history-panel {
  background-color: var(--background-color-dark); 
}