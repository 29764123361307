/* Position your nav links at the top right */
:root {
  --primary-color: #0068d7;
  --background-color-light: #ffffff;
  --background-color-dark: #111111;
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --accent-color: #0056b3;
  --gradient-dark: linear-gradient(135deg, #1e1e1e 0%, #333 100%);
  --gradient-light: linear-gradient(135deg, #ffffff 0%, #a8a8a8 100%);
  --gradient-light-menu: linear-gradient(180deg, #ffffff 0%, #e8e8e8 130%);

}


@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(120%);
    opacity: 0;

  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

body {
  background-color: var(--background-color-light);
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.4) 1px, transparent 1px);
  background-size: 15px 15px;
  object-fit: cover;
  color: var(--text-color-light);
  animation: breathe 20s ease-in-out infinite;
}

body.dark-mode {
  background-color: var(--background-color-dark);
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  object-fit: cover;
  color: var(--text-color-dark);
  animation: breathe 10s ease-in-out infinite;
}

@keyframes breathe {
  0%{
    background-size: 15px 15px;
    background-position: center;
    /* transform: translateX(0); */
  }
  50% {
    background-size: 16px 16px;
    background-position: center;
    /* transform: translateX(10%); */
  }
  100% {
    background-size: 15px 15px;
    background-position: center;
    /* transform: translateX(0); */
  }
}

/* App.css */
.app-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 150vw;
}
