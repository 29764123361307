/* Main layout styles */
.main-page {
  display: flex;
  height: 100vh;
}

.file-explorer {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 94vh;
  width: 83vw;
  margin-top: 3vh;
  margin-left: 1vw;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
}

.header-text {
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Top bar styles */
.search-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.search-bar {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 5px 15px;
  margin-right: 20px;
}

.search-bar input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  background-color: transparent;
}

.search-bar .search-icon {
  color: #888;
  margin-right: 10px;
}

.sort-options {
  display: flex;
  gap: 10px;
}

.sort-button {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  padding: 8px 15px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.sort-button:hover {
  background-color: #e0e0e0;
}

.sort-button .sort-icon {
  margin-left: 5px;
}

/* Main area styles */
.main-area {
  flex: 1;
  overflow-y: auto;
  padding: 20px 50px;
}

.file-folder-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  height: 5vh;
}

.folder-icon {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 30px;
}
.pdf-file-icon {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 7.5px;
  font-size: 30px;
}

.main-info {
  flex-grow: 1;
}

.right-side-items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item-over {
  background-color: #e0e0e0;
  border: 2px dashed #999;
}

.document-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 5vh;
}

.project{
  margin-right: 10px;
  font-size: 10px;
}

.top-row-buttons{
  display: flex;
}

.back-button{
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 15px;
  width: 15vw;
  font-size: 18px;
  cursor: pointer;
  margin-left: 50px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}

.back-button:hover {
  background-color: #0673e0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Document card styles */
.document-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: white;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.document-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.left-side-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-info {
  display: flex;
  flex-direction: column;
}

.docName {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin:0;
}

.docAdded {
  font-size: 12px;
  color: #888;
  margin: 0;
}

.right-side-items {
  display: flex;
  align-items: center;
}

.share-button, .view-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.share-button:hover, .view-button:hover {
  background-color: #357abd;
}

.status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 15px;
}

.docStatus {
  font-size: 14px;
  font-weight: 600;
  color: #4a90e2;
}

.docSource {
  font-size: 12px;
  color: #888;
}

.delete-icon {
  font-size: 20px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-right: 5px;
}
.edit-icon {
  font-size: 20px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.delete-icon:hover {
  color: #e74c3c;
}

/* Dark mode adjustments */
body.dark-mode .file-explorer {
  background-color: #1e2a38;
}

body.dark-mode .header-text {
  color: #ecf0f1;
}

body.dark-mode .search-and-sort {
  background-color: #2c3e50;
  border-bottom-color: #34495e;
}

body.dark-mode .search-bar,
body.dark-mode .sort-button {
  background-color: #34495e;
  border-color: #2c3e50;
  color: #ecf0f1;
}

body.dark-mode .search-bar input {
  color: #ecf0f1;
}

body.dark-mode .document-card {
  background-color: #2c3e50;
}

body.dark-mode .docName,
body.dark-mode .docStatus {
  color: #ecf0f1;
}

body.dark-mode .docAdded,
body.dark-mode .docSource {
  color: #bdc3c7;
}

body.dark-mode .share-button,
body.dark-mode .view-button {
  background-color: #3498db;
}

body.dark-mode .share-button:hover,
body.dark-mode .view-button:hover {
  background-color: #2980b9;
}

body.dark-mode .delete-icon {
  color: #bdc3c7;
}

body.dark-mode .delete-icon:hover {
  color: #e74c3c;
}

/* Fullscreen viewer styles */
.fullscreen-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.viewer-header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.close-button:hover {
  opacity: 0.7;
}

.fullscreen-viewer iframe {
  flex-grow: 1;
  border: none;
}

/* Styles for the XLSXViewer component if needed */
.fullscreen-viewer .xlsx-viewer {
  flex-grow: 1;
  background-color: white;
  overflow: auto;
}

/* Dark mode adjustments for fullscreen viewer */
body.dark-mode .fullscreen-viewer {
  background-color: rgba(0, 0, 0, 0.95);
}

body.dark-mode .close-button {
  color: #ecf0f1;
}

body.dark-mode .fullscreen-viewer .xlsx-viewer {
  background-color: #2c3e50;
  color: #ecf0f1;
}

/* New Folder Button */
.new-folder-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 15px;
  width: 15vw;
  font-size: 18px;
  cursor: pointer;
  margin-left: 50px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}

.breadcrumbs{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-top: 10px;
}

.new-folder-button:hover {
  background-color: #0673e0;
}

/* New Folder Modal */
.new-folder-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  width: 300px;
}

.new-folder-modal h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
}

.new-folder-modal input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.new-folder-modal button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.new-folder-modal button:hover {
  background-color: #357abd;
}

.button-folder-icon{
  font-size: 30px;
}

.new-folder-modal button:last-child {
  background-color: #e0e0e0;
  color: #333;
}

.new-folder-modal button:last-child:hover {
  background-color: #c0c0c0;
}

/* Dark mode adjustments for new folder modal */
body.dark-mode .new-folder-modal {
  background-color: #2c3e50;
  color: #ecf0f1;
}

body.dark-mode .new-folder-modal h2 {
  color: #ecf0f1;
}

body.dark-mode .new-folder-modal input {
  background-color: #34495e;
  border-color: #2c3e50;
  color: #ecf0f1;
}

body.dark-mode .new-folder-modal button {
  background-color: #3498db;
}

body.dark-mode .new-folder-modal button:hover {
  background-color: #2980b9;
}

body.dark-mode .new-folder-modal button:last-child {
  background-color: #34495e;
  color: #ecf0f1;
}

body.dark-mode .new-folder-modal button:last-child:hover {
  background-color: #2c3e50;
}