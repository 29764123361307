.upload-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* Align items to the top */
    height: 94vh;
    margin-top: 3vh;
    width: 99vw;
}

.big-loader{    
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 25;
    background-color: rgba(255, 255, 255, 0.5);
}



.cloud-upload-options {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cloud-button {
    margin-right: 10px;
    width: 7vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 7vw;
    border-radius: 15px;
    border: none;
    background-color: var(--primary-color);
    color: var(--background-color-light);
    cursor: pointer;
}

.upload-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 18%;
    /* Use percentage width */
    background-color: var(--background-color-light);
    border-radius: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    transform: translateX(120%);
    margin-left: 1vw;
    animation: slideRight 0.6s ease-in-out forwards;
    animation-delay: 0.5s;
    overflow-y: scroll;
}

.upload-history-header{
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.document-history-card {
    width: 95%;
    min-height: 5vh;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-left: 2%;
    background: var(--gradient-light-menu);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 2%;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.document-history-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color-light);
    /* Or any solid color you prefer */
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
}

.document-history-card:hover::before {
    opacity: 1;
}

/* Ensure the content of the card remains visible */
.document-history-card>* {
    position: relative;
    z-index: 2;
}

.logo {
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.document-history-card-header {
    margin-bottom: 0px;
    font-size: 14px;
    text-wrap: nowrap;
}

.date-added {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: rgb(169, 169, 169);
}

.details-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 5px;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 65%;
    /* Use percentage width */
    background-color: var(--background-color-light);
    border-radius: 15px;
    margin-left: 1vw;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    animation: slideIn 0.6s ease-in-out;

}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    margin-top: 20px;
}

h1 {
    font-size: 32px;
    margin: 0;
    margin-right: 30px;
}

.file-input-wrapper {
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
}

.choose-files-button {
    width: 7vw;
    border-radius: 15px;
    border: none;
    background-color: var(--primary-color);
    color: var(--background-color-light);
    cursor: pointer;
}

#drop-area {
    flex-grow: 1;
    width: 96%;
    margin-top: 2%;
    margin-bottom: 2%;
    overflow-y: scroll;
}

.new-database-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.new-database-input input[type="text"] {
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    width: 70%;
}

.new-database-input button {
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.new-database-input button:hover {
    background-color: #0056b3;
}

.validation-error {
    color: red;
    margin-top: 10px;
}

#drop-area {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#drop-area p {
    font-size: 24px;
    font-weight: bold;
}

#drop-area:hover {
    border-color: #000;
}

.overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.overlay-content {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.loader-text {
    font-size: 24px;
    margin: 20px;
}

.loader {
    border: 15px solid #f3f3f3;
    border-top: 15px solid #3498db;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body.dark-mode {
    .upload-container {
        background-color: var(--background-color-dark);
        transition: all 0.3s ease;
    }

    .upload-history {
        background-color: var(--background-color-dark);
        transition: all 0.3s ease;
    }

    .drop-area {
        border: 2px dashed #cdcdcd;
    }
}

/* File Card Styling */
.file-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.file-card-name{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    color: var(--text-color-dark);
}

/* .file-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px;
    background-color: var(--primary-color);
    border-radius: 15px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    height: 10vh;
} */

.remove-file {
    font-size: 16px;
    color: var(--text-color-dark);
    background-color: rgb(197, 0, 0);
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: none;
    margin-right: 30px;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    font-size: 14px;
}